import axios from 'axios';

export const getNotifications = async (userId: string) => {
  try {
    const response = await axios({
      method: 'get',
      url: `/v2/user/${userId}/notifications/active`,
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const readNotifications = async (userId: string, notificationId: string) => {
  try {
    const response = await axios({
      method: 'patch',
      url: `/v2/user/${userId}/notification/${notificationId}/read`,
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    });

    return {
      error: false,
      response: response.data.message,
    };
  } catch (error) {
    console.log(error);
  }
};

export const dismissNotifications = async (userId: string, notificationId: string) => {
  try {
    const response = await axios({
      method: 'patch',
      url: `/v2/user/${userId}/notification/${notificationId}/dismiss`,
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    });

    return {
      error: false,
      response: response.data.message,
    };
  } catch (error) {
    console.log(error);
  }
};

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { AppBar, Box, Button, Divider, Toolbar, Link, makeStyles, Hidden } from '@material-ui/core';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';

type TopBarProps = {
  className?: string;
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  toolbar: {
    height: 64,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    display: 'inline-table',
    whiteSpace: 'nowrap',
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const TopBar = ({ className, ...rest }: TopBarProps) => {
  const classes = useStyles();
  const { isAuthenticated, logout } = useAuth();
  const path = window.location.pathname;

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Hidden xsDown>
            <Logo className={classes.logo} />
          </Hidden>
          <Hidden smUp>
            <img
              src="/static/divergenceImages/logo_icon.png"
              alt="logo"
              style={{ height: 40, width: 40 }}
            />
          </Hidden>
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden smDown>
          <Link
            color="textSecondary"
            component="a"
            href="https://www.divergenceneuro.com/#About"
            className={classes.link}
            underline="none"
            variant="body2"
          >
            About Us
          </Link>
          <Link
            color="textSecondary"
            component="a"
            href="https://www.divergenceneuro.com/#mission"
            className={classes.link}
            underline="none"
            variant="body2"
          >
            Mission
          </Link>
          <Link
            color="textSecondary"
            component="a"
            href="https://www.divergenceneuro.com/#Vision"
            className={classes.link}
            underline="none"
            variant="body2"
          >
            Vision
          </Link>
          <Link
            color="textSecondary"
            component="a"
            href="https://www.divergenceneuro.com/product/"
            className={classes.link}
            underline="none"
            variant="body2"
          >
            Product
          </Link>
          <Link
            color="textSecondary"
            component="a"
            href="https://www.divergenceneuro.com/news/"
            className={classes.link}
            underline="none"
            variant="body2"
          >
            News
          </Link>
        </Hidden>
        {isAuthenticated && (
          <Button
            className={classes.link}
            color="secondary"
            variant="contained"
            component={RouterLink}
            to="/"
            size="small"
            onClick={() => logout()}
          >
            Log Out
          </Button>
        )}
        {!isAuthenticated && path !== '/' && (
          <>
            <Divider className={classes.divider} />
            <Button
              className={classes.link}
              component={RouterLink}
              color="secondary"
              to={path === '/register' ? '/login' : '/register'}
              variant="contained"
              size="small"
            >
              Sign In
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
